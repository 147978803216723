import React, { useState, useEffect } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import axios from 'axios';
import WalletConnection from './WalletConnection';
import TokenDropdown from './TokenDropdown';
import TokenDetails from './TokenDetails';
import TradingForm from './TradingForm';
import JupiterSwapComponent from './JupiterSwapComponent';
import './PhantomWalletConnect.css';

const PhantomWalletConnect = () => {
  const [wallet, setWallet] = useState(null); // Store the wallet object
  const [tokenNames, setTokenNames] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(null);
  const [apiKeys, setApiKeys] = useState({ dextools_api_key: '', quicknode_url: '' });
  const [botStatus, setBotStatus] = useState(null);
  const [swapDetails, setSwapDetails] = useState(null);

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const response = await axios.get('/api/keys/');
        setApiKeys(response.data);
      } catch (error) {
        console.error('Error fetching API keys:', error);
      }
    };

    fetchApiKeys();
  }, []);

  useEffect(() => {
    if (botStatus === 'success') {
      const fetchSwapDetails = async () => {
        try {
          const response = await axios.get('/api/get_swap_details/');
          if (response.data.status === 'success') {
            setSwapDetails(response.data.swapDetails);
          }
        } catch (error) {
          console.error('Error fetching swap details:', error);
        }
      };

      fetchSwapDetails();
    }
  }, [botStatus]);

  const handleWalletConnect = async (publicKey) => {
    const walletObj = { publicKey: new PublicKey(publicKey) };
    setWallet(walletObj);
    fetchTokens(walletObj.publicKey);
  };

  const fetchTokens = async (publicKey) => {
    if (!apiKeys.quicknode_url.startsWith('http')) {
      console.error('Invalid QuickNode URL:', apiKeys.quicknode_url);
      return;
    }

    try {
      const connection = new Connection(apiKeys.quicknode_url);

      const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
        publicKey,
        { programId: new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA') }
      );

      const tokensList = tokenAccounts.value.map((accountInfo) => {
        const parsedInfo = accountInfo.account.data.parsed.info;

        const mintAddress = parsedInfo.mint;
        const amount = parsedInfo.tokenAmount.uiAmount;
        const decimals = parsedInfo.tokenAmount.decimals;
        const adjustedAmount = Number(amount).toFixed(decimals);

        return {
          address: mintAddress,
          name: 'Loading...',
          amount: adjustedAmount,
        };
      });

      setTokenNames(tokensList);

      tokensList.forEach(token => fetchTokenName(token.address));

    } catch (err) {
      console.error('Failed to fetch tokens:', err);
    }
  };

  const fetchTokenName = async (address) => {
    try {
      const response = await axios.get('/proxy/api/', {
        params: {
          api_type: 'dextools',
          endpoint: `solana/${address}`,
        },
      });

      setTokenNames((prevNames) => 
        prevNames.map(token => 
          token.address === address 
            ? { ...token, name: response.data.data.name } 
            : token
        )
      );
    } catch (err) {
      console.error('Failed to fetch token name:', err);
    }
  };

  const handleFormSubmit = async (tokenAddress, usdAmount) => {
    try {
      const response = await axios.post('/api/start_trade_bot/', {
        initial_token_address: tokenAddress,
        usd_amount: usdAmount,
      });
      setBotStatus(response.data.status);
    } catch (err) {
      console.error('Failed to start the bot:', err);
      setBotStatus('Failed to start the bot.');
    }
  };

  const handleTokenSelection = (token) => {
    setSelectedToken(token);
    setTokenPrice(null);
    fetchTokenPrice(token.address);
  };

  const fetchTokenPrice = async (address) => {
    try {
      const response = await axios.get('/proxy/api/', {
        params: {
          api_type: 'dextools',
          endpoint: `solana/${address}/price`,
        },
      });

      setTokenPrice(response.data.data.price);
    } catch (err) {
      console.error('Failed to fetch token price:', err);
    }
  };

  return (
    <div className="wallet-container">
      <h2>Phantom Wallet Connection</h2>
      {wallet ? (
        <div>
          <p className="wallet-address">Connected Wallet Address: {wallet.publicKey.toString()}</p>
          <h3>Tokens in Wallet:</h3>
          <TokenDropdown tokens={tokenNames} onSelect={handleTokenSelection} />
          {selectedToken && <TokenDetails token={selectedToken} price={tokenPrice} />}
          
          {/* Render the TradingForm component */}
          <TradingForm tokens={tokenNames} onSubmit={handleFormSubmit} />
          
          {/* Display bot status */}
          {botStatus && <p className="bot-status">Bot Status: {botStatus}</p>}

          {/* Render the JupiterSwapComponent when swap details are available */}
          {swapDetails && (
            <JupiterSwapComponent
              wallet={wallet}
              quicknodeUrl={apiKeys.quicknode_url}
            />
          )}
        </div>
      ) : (
        <WalletConnection apiKeys={apiKeys} onConnect={handleWalletConnect} />
      )}
    </div>
  );
};

export default PhantomWalletConnect;