// src/components/ConfirmModal.js

import React from 'react';
import './Modal.css';

const ConfirmModal = ({ toggleModal }) => {
  const message = 'Your email has been confirmed successfully! You can now log in.'; // Directly assign the message

  const handleLogin = () => {
    toggleModal(); // Close the confirm modal
    // Trigger the login modal (assumes you have a method to toggle login modal in Navbar)
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-button" onClick={toggleModal}>&times;</span>
        <h2>Confirmation</h2>
        <p>{message}</p>
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
};

export default ConfirmModal;