import React from 'react';
import { Connection, PublicKey } from '@solana/web3.js'; // Importing here

const WalletConnection = ({ apiKeys, onConnect }) => {
  const checkIfPhantomInstalled = () => {
    return window.solana && window.solana.isPhantom;
  };

  const connectWallet = async () => {
    if (!apiKeys.quicknode_url) {
      console.error('QuickNode URL is not provided!');
      return;
    }

    if (checkIfPhantomInstalled()) {
      try {
        const response = await window.solana.connect({ onlyIfTrusted: false });
        onConnect(response.publicKey);
      } catch (err) {
        console.error('Failed to connect to wallet:', err);
      }
    } else {
      alert('Phantom wallet is not installed. Please install it to use this feature.');
    }
  };

  return (
    <button onClick={connectWallet} className="connect-button">
      Connect to Phantom Wallet
    </button>
  );
};

export default WalletConnection;