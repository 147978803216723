import React, { useState, useEffect } from 'react';
import { PublicKey } from '@solana/web3.js';
import './SolanaComponent.css'; // Import the CSS file for styling

const SolanaComponent = ({ onConnect, onSelectToken }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [tokenList, setTokenList] = useState([]); // List of tokens available for trading
  const [selectedToken, setSelectedToken] = useState('');

  useEffect(() => {
    // Fetch token list - In a real application, you would fetch this from an API or Solana registry
    const tokens = [
      { symbol: 'SOL', address: 'So11111111111111111111111111111111111111112' },
      { symbol: 'USDC', address: 'Es9vMFrzaCER9d88JPGzZk9gfqWhMvqDwwBbb8QzMYQ1' },
      // Add more tokens as needed
    ];
    setTokenList(tokens);
  }, []);

  const connectPhantom = async () => {
    if (window.solana && window.solana.isPhantom) {
      try {
        if (window.solana.isConnected) {
          window.solana.disconnect();
          console.log("Disconnected previous Phantom Wallet connection.");
        }

        const resp = await window.solana.connect();
        const publicKey = new PublicKey(resp.publicKey.toString());
        setWalletAddress(publicKey.toString());
        onConnect(publicKey.toString()); // Pass the address to the parent component
      } catch (error) {
        console.error("Error connecting to Phantom:", error);
      }
    } else {
      alert('Phantom Wallet not found. Please install the Phantom Wallet.');
    }
  };

  const handleTokenSelect = (event) => {
    const selected = tokenList.find(token => token.symbol === event.target.value);
    setSelectedToken(selected.symbol);
    onSelectToken(selected); // Pass the selected token to the parent component
  };

  return (
    <div className="solana-container">
      <button className="wallet-button" onClick={connectPhantom}>
        {walletAddress ? `Connected: ${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}` : 'Connect Phantom Wallet'}
      </button>

      {walletAddress && (
        <div className="token-selector">
          <label htmlFor="token-select">Select Token:</label>
          <select id="token-select" value={selectedToken} onChange={handleTokenSelect}>
            <option value="" disabled>Select a token</option>
            {tokenList.map(token => (
              <option key={token.address} value={token.symbol}>
                {token.symbol}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default SolanaComponent;