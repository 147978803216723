// src/components/Showcase/Showcase.js
import React from 'react';
import './Showcase.css';

const Showcase = () => {
  const pages = [
    {
      title: 'Mr May Who',
      url: 'https://www.mrmaywho.com', // URL of the live preview
      description: 'This is a site to purchase my website packages.',
    },
    {
      title: 'Who Joose',
      url: 'https://www.whojoose.com', // URL of the live preview
      description: 'This is a site to purchase my Juice Beverages',
    },
    {
      title: 'Ces Moments By Tricee',
      url: 'https://www.cmbtricee.com', // URL of the live preview
      description: 'This is a page built for my mom to display her available cakes for sale.',
    },
    // Add more pages as needed
  ];

  return (
    <div className="showcase" id='showcase'>
      <h2>Showcase</h2>
      <div className="showcase-grid">
        {pages.map((page, index) => (
          <div className="showcase-item" key={index}>
            <div className="showcase-iframe">
              <iframe src={page.url} title={page.title} />
            </div>
            <div className="showcase-content">
              <h3>{page.title}</h3>
              <p>{page.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Showcase;