// src/components/LoginModal.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import './Modal.css';
import { AuthContext } from '../../../context/AuthContext';  // Import AuthContext

const getCSRFToken = () => {
  let csrfToken = null;
  if (document.cookie && document.cookie !== '') {
    document.cookie.split(';').forEach(cookie => {
      const [key, value] = cookie.trim().split('=');
      if (key === 'csrftoken') {
        csrfToken = value;
      }
    });
  }
  return csrfToken;
};

const LoginModal = ({ toggleModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);  // Destructure the login function from AuthContext

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const csrfToken = getCSRFToken();

      const response = await axios.post(
        '/api/auth/login/',
        {
          email,
          password,
        },
        {
          headers: {
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const token = response.data.key;
      localStorage.setItem('authToken', token); // Store the token in local storage

      // Update authentication status in the context
      login(token);

      setMessage('Logged in successfully');
      setLoading(false);

      // Refresh the page to ensure the state is updated
      window.location.reload();

    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data?.non_field_errors?.[0] || 'Login failed';
      setMessage(errorMessage);
      console.error('Login error:', error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-button" onClick={toggleModal}>&times;</span>
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        {message && <p className="error-message">{message}</p>}
      </div>
    </div>
  );
};

export default LoginModal;