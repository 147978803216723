// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [walletProvider, setWalletProvider] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            fetchUserData();
        }
    }, []);

    const fetchUserData = () => {
        axios.get('/api/auth/user/')
            .then(response => {
                setIsAuthenticated(true);
                setUser(response.data);
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
                setIsAuthenticated(false);
                setUser(null);
            });
    };

    const login = (token) => {
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
        setIsAuthenticated(true);
        fetchUserData();
    };

    const logout = () => {
        // Remove the authentication token from local storage
        localStorage.removeItem('authToken');
    
        // Reset authentication state and user information
        setIsAuthenticated(false);
        setUser(null);
    
        // Remove the Authorization header from axios
        delete axios.defaults.headers.common['Authorization'];
    
        // Disconnect Phantom Wallet if it's connected
        if (walletProvider === 'Phantom' && window.solana && window.solana.isConnected) {
            window.solana.disconnect();
            console.log('Phantom Wallet disconnected');
        }
    
        // Reset wallet provider state
        setWalletProvider(null);
    
        // Reload the page to reset the application state
        window.location.reload();
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout, setWalletProvider }}>
            {children}
        </AuthContext.Provider>
    );
};