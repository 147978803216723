import React from 'react';

const TokenDetails = ({ token, price }) => {
  // Calculate the total value in USD
  const usdValue = price !== null ? (token.amount * price).toFixed(2) : 'Calculating...';

  return (
    <div className="token-details">
      <p><strong>Token Name:</strong> {token.name}</p>
      <p><strong>Token Address:</strong> {token.address}</p>
      <p><strong>Token Amount:</strong> {token.amount}</p> 
      {price !== null ? (
        <>
          <p><strong>Token Price (USD):</strong> ${price}</p>
          <p><strong>Total Value (USD):</strong> ${usdValue}</p> {/* Display total value */}
        </>
      ) : (
        <p>Loading price...</p>
      )}
    </div>
  );
};

export default TokenDetails;