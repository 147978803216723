import React, { useState, useEffect } from 'react';
import { Connection, VersionedTransaction } from '@solana/web3.js';
import fetch from 'cross-fetch';

const JupiterSwapComponent = ({ wallet, quicknodeUrl }) => {
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [swapDetails, setSwapDetails] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkForSwap();
    }, 5000); // Check every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const checkForSwap = async () => {
    try {
      const response = await fetch('/api/get_swap_details'); // Replace with your endpoint
      const data = await response.json();

      if (data.status === 'success' && data.swapDetails.shouldSwap) {
        setSwapDetails(data.swapDetails);
        performSwap(data.swapDetails.inputMint, data.swapDetails.outputMint, data.swapDetails.amount);
      }
    } catch (error) {
      console.error('Error checking for swap:', error);
    }
  };

  const performSwap = async (inputMint, outputMint, amount) => {
    try {
      const connection = new Connection(quicknodeUrl);

      // Get the swap quote dynamically
      const quoteResponse = await fetch(`https://quote-api.jup.ag/v6/quote?inputMint=${inputMint}&outputMint=${outputMint}&amount=${amount}&slippageBps=50`);

      if (!quoteResponse.ok) {
        throw new Error(`Failed to fetch quote: ${quoteResponse.statusText}`);
      }

      const quoteData = await quoteResponse.json();

      // Get the serialized swap transaction
      const swapResponse = await fetch('https://quote-api.jup.ag/v6/swap', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quoteResponse: quoteData,
          userPublicKey: wallet.publicKey.toString(),
          wrapAndUnwrapSol: true,
        }),
      });

      if (!swapResponse.ok) {
        throw new Error(`Failed to execute swap: ${swapResponse.statusText}`);
      }

      const { swapTransaction } = await swapResponse.json();

      // Decode the base64-encoded transaction
      const swapTransactionBuf = Uint8Array.from(atob(swapTransaction), c => c.charCodeAt(0));
      const transaction = VersionedTransaction.deserialize(swapTransactionBuf);

      // Sign the transaction
      transaction.sign([wallet.payer]);

      // Get the latest block hash
      const latestBlockHash = await connection.getLatestBlockhash();

      // Serialize and send the transaction
      const rawTransaction = transaction.serialize();
      const txid = await connection.sendRawTransaction(rawTransaction, {
        skipPreflight: true,
        maxRetries: 2,
      });

      // Confirm the transaction
      await connection.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature: txid,
      });

      setTransactionStatus(`Transaction successful: https://solscan.io/tx/${txid}`);
    } catch (error) {
      console.error('Swap failed:', error);
      setTransactionStatus('Transaction failed. Please try again.');
    }
  };

  return (
    <div>
      <button onClick={() => performSwap(swapDetails?.inputMint, swapDetails?.outputMint, swapDetails?.amount)}>Perform Swap</button>
      {transactionStatus && <p>{transactionStatus}</p>}
    </div>
  );
};

export default JupiterSwapComponent;