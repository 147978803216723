import React, { useState } from 'react';
import axios from 'axios';

const TradingForm = ({ tokens, onSubmit }) => {
  const [selectedToken, setSelectedToken] = useState('');
  const [usdAmount, setUsdAmount] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedToken || !usdAmount) {
      alert('Please select a token and enter the amount.');
      return;
    }
    onSubmit(selectedToken, usdAmount); // Call the onSubmit prop function
  };

  return (
    <form onSubmit={handleSubmit} className="trading-form">
      <div className="form-group">
        <label htmlFor="token">Select Token:</label>
        <select
          id="token"
          value={selectedToken}
          onChange={(e) => setSelectedToken(e.target.value)}
        >
          <option value="">-- Select Token --</option>
          {tokens.map((token) => (
            <option key={token.address} value={token.address}>
              {token.name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="usdAmount">Amount in USD:</label>
        <input
          type="number"
          id="usdAmount"
          value={usdAmount}
          onChange={(e) => setUsdAmount(e.target.value)}
          placeholder="Enter amount in USD"
        />
      </div>

      <button type="submit" className="submit-button">
        Start Trading
      </button>
    </form>
  );
};

export default TradingForm;