import React from 'react';

const TokenDropdown = ({ tokens, onSelect }) => {
  const handleChange = (event) => {
    const selectedToken = tokens.find(token => token.address === event.target.value);
    onSelect(selectedToken);
  };

  return (
    <select onChange={handleChange} className="token-dropdown">
      <option value="">Select a token</option>
      {tokens.map((token) => (
        <option key={token.address} value={token.address}>
          {token.name} ({token.amount}) 
        </option>
      ))}
    </select>
  );
};

export default TokenDropdown;