import React, { useContext, useState, useEffect } from 'react';
import SolanaComponent from '../Solana/SolanaComponent'; // Import the SolanaComponent
import './TradeBot.css'; // Import the CSS file for styling
import { AuthContext } from '../../../context/AuthContext';

const TradingBotSetup = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [walletAddress, setWalletAddress] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [amount, setAmount] = useState(''); // State to hold the amount for the trade
  const [transactions, setTransactions] = useState([]); // State to hold transaction history

  const handleWalletConnect = (address) => {
    setWalletAddress(address);
  };

  const handleTokenSelect = (token) => {
    setSelectedToken(token);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const initiateTrade = async (tradeType) => {
    if (!walletAddress || !selectedToken || !amount) {
      alert('Please connect your wallet, select a token, and enter an amount.');
      return;
    }

    const endpoint = tradeType === 'buy' ? '/api/trading_bot/initiate-buy/' : '/api/trading_bot/initiate-sell/';
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: selectedToken.symbol,
        amount: amount,
      }),
    });

    const data = await response.json();
    if (data.status === 'success') {
      console.log(`${tradeType.charAt(0).toUpperCase() + tradeType.slice(1)} transaction initiated:`, data.transaction);
      fetchTradeHistory(); // Fetch the updated trade history after initiating a trade
    } else {
      console.error('Transaction failed:', data.error);
    }
  };

  const fetchTradeHistory = async () => {
    const response = await fetch('/api/trading_bot/trade-history/');
    const data = await response.json();
    setTransactions(data.trades);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchTradeHistory(); // Fetch trade history when the component mounts
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <p>Please Log in to use Cryptor Bot.</p>;
  }

  return (
    <div className="trading-bot-container">
      <h1>Trading Bot Setup</h1>
      <SolanaComponent onConnect={handleWalletConnect} onSelectToken={handleTokenSelect} />
      {walletAddress && selectedToken && (
        <>
          <div className="amount-input">
            <label htmlFor="amount">Enter Amount:</label>
            <input
              id="amount"
              type="number"
              value={amount}
              onChange={handleAmountChange}
              placeholder="Enter amount to trade"
            />
          </div>

          <div className="trade-buttons">
            <button onClick={() => initiateTrade('buy')}>Buy {selectedToken.symbol}</button>
            <button onClick={() => initiateTrade('sell')}>Sell {selectedToken.symbol}</button>
          </div>
        </>
      )}
      
      {transactions.length > 0 && (
        <div className="transaction-history">
          <h2>Transaction History</h2>
          <ul>
            {transactions.map((tx, index) => (
              <li key={index}>
                {tx.timestamp}: {tx.trade_type.toUpperCase()} {tx.amount} {tx.token} - Wallet: {tx.wallet_address}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TradingBotSetup;