import React from 'react';
import { useSpring, animated } from 'react-spring';
import './Banner.css';
import profileImage from './cyrptor_bot_logo.svg';

const Banner = () => {
  const profileAnimation = useSpring({
    from: { opacity: 0, transform: 'scale(0.5)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 320, friction: 60 },
  });

  const textAnimation = useSpring({
    from: { opacity: 0, transform: 'translateX(-100px)' },
    to: { opacity: 1, transform: 'translateX(0px)' },
    config: { tension: 200, friction: 75 },
  });


  return (
    <div className="banner">
      <animated.div style={profileAnimation} className="profile-picture">
        <img src={profileImage} alt="Profile" />
      </animated.div>
      <animated.div style={textAnimation} className="banner-text">
        <h1>Cryptor Bot</h1>
        <p>Best Bot to increase your Investments!</p>
      </animated.div>
    </div>
  );
};

export default Banner;