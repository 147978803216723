// src/components/AboutUs/AboutUs.js
import React, { useEffect, useState } from 'react';
import { useSprings, animated } from 'react-spring';
import './AboutUs.css';

const AboutUs = () => {
  const paragraph = "I am Charles Mayhew, Currently engaging in the world of Full-Stack Web Engineering. While you're here, you should take a look at some of the projects that I have created. Hope you find my work interesting, and contact me if you're interested in developing some innovative ideas!";
  const words = paragraph.split(' ');
  const [inView, setInView] = useState(false);

  const [springs, api] = useSprings(words.length, index => ({
    opacity: 0,
    transform: 'translateX(-20px)',
    config: { tension: 200, friction: 20 },
  }));

  useEffect(() => {
    const handleScroll = () => {
      const aboutUsElement = document.getElementById('about-us');
      const rect = aboutUsElement.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        setInView(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (inView) {
      api.start(index => ({
        opacity: 1,
        transform: 'translateX(0px)',
        delay: index * 100,
      }));
    }
  }, [inView, api]);

  return (
    <div id="about-us" className="about-us">
      <h2>Meet Me</h2>
      <p>
        {springs.map((style, index) => (
          <animated.span key={index} style={style}>
            {words[index]}{' '}
          </animated.span>
        ))}
      </p>
    </div>
  );
};

export default AboutUs;