import React, { useState } from 'react';
import axios from 'axios';
import './Modal.css';

const RegisterModal = ({ toggleModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [honeypot, setHoneypot] = useState(''); // Honeypot field state
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    // Honeypot check - if honeypot is filled out, it's likely a bot
    if (honeypot !== '') {
      setMessage('Registration failed');
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      // Send a POST request to the registration endpoint with the user's details
      await axios.post('/auth/registration/', {
        email,
        password1: password,
        password2: confirmPassword,
      });

      // Notify the user to check their email for confirmation
      setMessage('Registration successful! Please check your email to verify your account.');
      setLoading(false);

      // Optionally, close the modal after a short delay to allow the user to read the message
      setTimeout(() => {
        toggleModal();
      }, 5000); // Adjust timing as needed
    } catch (error) {
      setLoading(false);

      // Provide detailed error feedback
      const errorMessage = error.response?.data?.non_field_errors?.[0] ||
        error.response?.data?.email?.[0] ||
        error.response?.data?.password1?.[0] ||
        'Registration failed';

      setMessage(errorMessage);
      console.error('Registration error:', error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-button" onClick={toggleModal}>&times;</span>
        <h2>Register</h2>
        <form onSubmit={handleRegister}>
          <div style={{ display: 'none' }}>
            <label>Honeypot:</label>
            <input
              type="text"
              value={honeypot}
              onChange={(e) => setHoneypot(e.target.value)}
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
};

export default RegisterModal;