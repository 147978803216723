// src/renderComponents.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Banner from './components/Banner/Banner';
import AboutUs from './components/About/AboutUs';
import Showcase from './components/Showcase/Showcase';
import ContactUs from './components/Contact/ContactUs';
import Scroll from './components/ScrollToTop/ScrollToTop';
import TradeBot from './components/Swap/SwapComponent/TradeBot';
import PhantomWalletConnect from './components/PhantomWalletConnect/PhantomWalletConnect';
import { AuthProvider } from './context/AuthContext';

 
function renderComponent(Component, elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    const root = createRoot(element);
    root.render(
    <React.StrictMode>
      <AuthProvider>
        <Component />
      </AuthProvider>
    </React.StrictMode>);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  renderComponent(Navbar, 'navbar-root');
  renderComponent(Footer, 'footer-root');
  renderComponent(Banner, 'banner-root');
  renderComponent(AboutUs, 'about-us-root');
  renderComponent(Showcase, 'showcase-root');
  renderComponent(ContactUs, 'contact-us-root');
  renderComponent(Scroll, 'scroll-root');
  renderComponent(TradeBot, 'TradeBot-root');
  renderComponent(PhantomWalletConnect, 'phantom-root');
});